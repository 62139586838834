import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { filterProducts, HIGHLIGHTED_CATEGORY } from 'modules/utils/saleCategoriesAndFilters';
import { userSelector } from 'modules/currentUser';

import useResponsive from 'hooks/Navigation/useResponsive';
import useI18n from 'hooks/I18n/useI18n';

import { getProducts } from 'models/distributions.js';
import { groupProductsByAlreadyOrderedFarms } from 'models/products';

import Heading from 'components/Heading.jsx';
import ProductCard, { MODE_LARGE } from 'components/Sale/ProductIdentity/ProductCard.jsx';

const WeeklySelection = props => {
    const { distributionId, assemblyId } = props;
    const { trans, i18n } = useI18n();
    const isSmallWidth = useResponsive();
    const user = useSelector(userSelector);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        distributionId &&
            getProducts(distributionId, user, { perPage: 12, onlyHighlighted: true }).then(res => {
                const highlightedProductsByFarm = groupProductsByAlreadyOrderedFarms(
                    filterProducts(
                        HIGHLIGHTED_CATEGORY,
                        null,
                        null
                    )(res.products.map(product => ({ ...product, farm: { id: product.farmId } })))
                );
                setProducts(
                    R.flatten(R.pluck('products', highlightedProductsByFarm))
                        .sort((a, b) => a.sortingPriority - b.sortingPriority)
                        .slice(0, 4)
                );
            });
    }, [distributionId, user]);

    return products.length ? (
        <div className="pi-hive-homepage-products">
            <Heading size={4} productIdentity>
                {trans('products.weeklySelection')}
            </Heading>
            <div className="pi-hive-homepage-products-list">
                {products.map(product => (
                    <a
                        href={`/${i18n}/assemblies/${assemblyId}/collections/${distributionId}/products/${product.id}`}
                    >
                        <ProductCard
                            assemblyId={assemblyId}
                            distributionId={distributionId}
                            product={product}
                            isLabelDescriptionAvailable={false}
                            mode={isSmallWidth ? null : MODE_LARGE}
                            showProducer={false}
                        />
                    </a>
                ))}
            </div>
        </div>
    ) : null;
};

WeeklySelection.propTypes = {
    distributionId: PropTypes.number.isRequired,
    assemblyId: PropTypes.number.isRequired,
};

export default WeeklySelection;
